@import url('https://fonts.googleapis.com/css2?family=Golos+Text:wght@400..900&display=swap');

html, body {
  font-family: "Golos Text", sans-serif;
  scroll-behavior: smooth;
  overflow-x: hidden
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.bg-main {
  background-image: url("./assets/imgs/bg-main.png");
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 0 0 40px 40px;
}

.bg-ind {
  background: linear-gradient(270deg, rgba(255, 255, 255, 0.5) 8.41%, rgba(255, 255, 255, 0) 103.15%);
}

@media (max-width:800px) {
  .bg-main {
    background-image: url("./assets/imgs/bg-main-mob.png");
  }
}